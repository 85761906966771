:root {
  --sitecolor: #D9942D;
  --sitecolor10: #fbf4ea;
  --sitecolor15: #f9efe0;
}
:root body.c2 {
  --sitecolor: #6fb54b;
  --sitecolor10: #f1f8ed;
  --sitecolor15: #e9f4e4;
}
:root body.c3 {
  --sitecolor: #00499d;
  --sitecolor10: #e6edf5;
  --sitecolor15: #d9e4f0;
}
:root body.c4 {
  --sitecolor: #00a4d8;
  --sitecolor10: #e6f6fb;
  --sitecolor15: #d9f1f9;
}
:root body.c5 {
  --sitecolor: #87888a;
  --sitecolor10: #f3f3f3;
  --sitecolor15: #ededed;
}
:root body.c6 {
  --sitecolor: #00325e;
  --sitecolor10: #e6ebef;
  --sitecolor15: #d9e0e7;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  line-height: 1.125;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-color: #333333;
  border-radius: 20px;
  font-family: 'BitterBold', helvetica, sans-serif;
}
.button:hover,
.button:focus {
  color: #fff !important;
}
.c1 .button:hover,
.c1 .button:focus {
  background-color: #D9942D;
}
.c1 .button:active {
  background-color: #e1a957;
}
.c2 .button:hover,
.c2 .button:focus {
  background-color: #6fb54b;
}
.c2 .button:active {
  background-color: #8cc46f;
}
.c3 .button:hover,
.c3 .button:focus {
  background-color: #00499d;
}
.c3 .button:active {
  background-color: #336db1;
}
.c4 .button:hover,
.c4 .button:focus {
  background-color: #00a4d8;
}
.c4 .button:active {
  background-color: #33b6e0;
}
.c5 .button:hover,
.c5 .button:focus {
  background-color: #87888a;
}
.c5 .button:active {
  background-color: #9fa0a1;
}
.c6 .button:hover,
.c6 .button:focus {
  background-color: #00325e;
}
.c6 .button:active {
  background-color: #335b7e;
}
.desk {
  max-width: 92%;
}
#home {
  margin: 25px 0 0;
}
.section--multimood {
  margin-top: 160px;
}
.footarea {
  margin: 15px 0;
}
.footpart {
  width: 50%;
  margin: 15px 0;
}
@media (max-width: 1299px) {
  .topservices {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 35px;
  }
  #services {
    order: 2;
    margin-top: 12px;
    margin-bottom: 0;
  }
  #social {
    margin-left: 0;
  }
  .service-group {
    order: 1;
  }
}
@media (max-width: 1024px) {
  .topservices {
    margin-top: 80px;
    margin-bottom: 10px;
  }
}
.energy__unit {
  width: 31%;
}
@media (max-width: 1024px) {
  .section--header {
    transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
    border-bottom: 1px solid #fff;
  }
  .section--header.cb-scroll-triggered--active {
    border-color: #333333;
  }
  #home {
    transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .topservices {
    max-height: 80px;
    transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--header.cb-scroll-triggered--active #home {
    height: 60px;
    margin: 20px 0;
  }
  .section--header.cb-scroll-triggered--active .topservices {
    opacity: 0;
    max-height: 0;
    margin: 40px 0 0;
    pointer-events: none;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .cb-album .body,
.area .head,
.area .foot,
.area .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .head,
.area > .slim .foot,
.area > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.area > .slim.cb-album .body {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north .cb-album .body,
.north .head,
.north .foot,
.north .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.north > .slim {
  width: 50%;
}
.north > .slim .head,
.north > .slim .foot,
.north > .slim .part {
  margin-right: 10%;
  margin-left: 10%;
  width: 80%;
}
.north > .slim.cb-album .body {
  margin-right: 10%;
  margin-left: 10%;
  width: 80%;
}
.north > .slim .tiny {
  width: 80%;
}
div.main {
  width: 104%;
  margin-left: -2%;
}
div.north {
  width: 110%;
  margin-left: -5%;
}
div.areaTen {
  width: 104%;
  margin-left: -2%;
}
div.north div.unit.wide div.part {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}
div.areaTen div.unit.slim {
  width: 33.33333333%;
}
div.areaTen div.unit.slim div.part {
  margin-left: 6.30252101%;
  margin-right: 6.30252101%;
  width: 87.39495798%;
}
/*# sourceMappingURL=./screen-medium.css.map */